export const colors = {
    background: '#353849',
    text: '#FFFFFF',
    secondText: '#03CC90',
    thirdText: '#232734',
    forthText: '#3F4354',
    waves2a: '#d5e6ff',
    waves2b: '#376389',
    backgroundAbout: '#376389',
    white: '#FFFFFF',
  };
  