import React from 'react';

const SectionFirst = () => {
  return (
    <div>
      <div className="spacer waves-2"></div>
    </div>
  );
};

export default SectionFirst;
